@use '@/styles/utils/mixins.scss' as *;

.guidelinesSection {
  scroll-margin-top: 7.5rem;
}

.guidelinesSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    @include tab() {
      flex-direction: column;
    }
  }
  .sectionDescription {
    max-width: 29rem;
  }
  .guidelinesList {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
  .guidelineItem {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
      gap: 1.25rem;
    }
  }
  .guidelineImageContainer {
    position: relative;

    .guidelineImage {
      border-radius: 1.25rem;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
  .guidelineContent {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    grid-column: span 2;
    @include tab() {
      grid-column: span 1;
    }
  }
  .guidelineFooter {
    padding-top: 2.5rem;
    margin-top: auto;
  }
}
